import React, { useState } from 'react';
import { db } from '../firebase';  
import { collection, addDoc } from 'firebase/firestore';
import '../App.css';

function EmailSubscription() {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
    setTouched(true);
  };

  const getLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      return {
        country: data.country_name,
        region: data.region,
        city: data.city
      };
    } catch (error) {
      console.error('Error fetching location:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid && email) {
      setLoading(true);
      try {
        const location = await getLocation();
        const now = new Date();
        await addDoc(collection(db, 'email_subscription'), {
          email: email,
          registeredAt: now,
          location: location || 'Unknown'
        });
        alert('メールアドレスが登録されました。');
        setEmail('');
        setIsValid(true);
        setTouched(false);
      } catch (error) {
        console.error("Error adding document: ", error);
        alert('登録中にエラーが発生しました。もう一度お試しください。');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="email-subscription">
      <img 
        src="/images/conoty_top.png" 
        alt="Conoty Top" 
        className="email-subscription-image"
      />
      <div className="email-subscription-message">
        <p>
          世界初？子供のお知らせを管理する専用アプリ「CONOTY」です。
        </p>
        <p>
          2024年秋にリリース予定。
        </p>
        <p>
          アプリは無料で使えます。
        </p>
        <p>
          最新のリリース情報を受け取りたい方は下記よりメールアドレスを登録ください。
        </p>
      </div>
      <form onSubmit={handleSubmit} className="email-subscription-form">
        <div>
          <input 
            type="email" 
            value={email} 
            onChange={handleEmailChange}
            onBlur={() => setTouched(true)}
            placeholder="メールアドレスを入力してください"
            disabled={loading}
            className="email-subscription-input"
          />
          {!isValid && touched && (
            <p className="email-subscription-error">
              正しい形式で入力してください
            </p>
          )}
        </div>
        <button 
          type="submit" 
          disabled={!isValid || !email || loading}
          className="email-subscription-button"
        >
          {loading ? '登録中...' : 'メールアドレスを登録'}
        </button>
      </form>
      <footer className="footer">
        © 2024 CONOTY by AINNA Co., Ltd. All rights reserved.
      </footer>
    </div>
  );
}

export default EmailSubscription;