import React from 'react';
import EmailSubscription from './components/EmailSubscription';

function App() {
  return (
    <div className="App">
      <EmailSubscription />
    </div>
  );
}

export default App;